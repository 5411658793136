import('./bootstrap');

if (process.env.TARGET_ENV === 'msw') {
  (async (): Promise<void> => {
    const { worker } = await import('./mocks/worker');
    worker.start();
  })();
}

export {};
